import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Illustration from '../components/illustration'
import SectionService from '../components/section-service'

export default function ServicesPage({ data, location }) {
  const { wordpressPage, allWordpressWpService } = data
  const { acf } = wordpressPage
  const { services_header, seo, open_graph, twitter } = acf
  return (
    <Layout location={location.pathname}>
      <Helmet
        title={seo.title}
        meta={[
          { name: 'description', content: seo.description },
          { name: 'og:title', content: open_graph.title },
          { name: 'og:description', content: open_graph.description },
          { name: 'og:image', content: open_graph.image.source_url },
          { name: 'og:url', content: 'https://www.modinfinity.com/services' },
          { name: 'twitter:title', content: twitter.title },
          { name: 'twitter:description', content: twitter.description },
          { name: 'twitter:image', content: twitter.image.source_url },
          { name: 'twitter:card', content: twitter.card_size },
        ]}
      />
      <section className="container mx-auto px-2 md:px-0 md:py-32 xxl:py-40 md:flex md:flex-row-reverse md:items-center">
        <div className="md:w-1/2 py-3 md:py-0 md:px-2 xl:px-10 xxl:px-20">
          <Illustration illustration={services_header.illustration} />
        </div>
        <div className="md:w-1/2 md:px-2 xl:px-10 xxl:pl-20 xxl:pr-24">
          <h1 className="mb-4 md:mb-6 xxl:mb-10 font-black text-4xl lg:text-6xl xxl:text-7xl text-indigo-darkest text-center md:text-left">
            {services_header.title}
          </h1>
          <p className="text-xl text-indigo-darkest font-light leading-normal">
            {services_header.copy}
          </p>
        </div>
      </section>
      <section>
        {allWordpressWpService.edges.map(({ node }) => (
          <SectionService service={node} />
        ))}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "services" }) {
      acf {
        services_header {
          title
          copy
          illustration
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
    allWordpressWpService(sort: { fields: acf___position, order: ASC }) {
      edges {
        node {
          title
          slug
          acf {
            short_copy
            position
            illustration
            image_position
            price
          }
        }
      }
    }
  }
`
