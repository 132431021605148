import React from 'react'

import Illustration from './illustration'

export default function SectionService({ service }) {
  const { image_position } = service.acf

  let position_class = 'flex-row'

  switch (image_position) {
    case 'left':
      position_class = 'flex-row'
      break
    case 'right':
      position_class = 'flex-row-reverse'
      break
    default:
      position_class = 'flex-row'
  }
  return (
    <div
      id={service.slug}
      className={`container mx-auto px-2 py-10 md:py-16 xxl:py-32 md:flex md:${position_class} md:items-center`}
    >
      <div className="md:w-1/2 md:px-4 xl:px-10 xxl:px-20">
        <Illustration illustration={service.acf.illustration} />
      </div>
      <div className="md:w-1/2 mt-4 md:mt-0 md:px-4 xl:px-10 xxl:pl-20 xxl:pr-24">
        <h3 className="mb-4 text-center md:text-left text-2xl lg:text-4xl xxl:text-5xl text-indigo-darkest font-black">
          {service.title}
        </h3>
        {service.acf.price && (
          <p className="text-xl lg:text-2xl text-indigo-darkest text-center md:text-left font-bold leading-normal">
            {service.acf.price}
          </p>
        )}
        <p className="mt-4 text-xl text-indigo-darkest font-light leading-normal">
          {service.acf.short_copy}
        </p>
      </div>
    </div>
  )
}
